<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #content>
          <TabView>
            <TabPanel>
              <template #header>
                <span>
                  Portabilidade ({{ compraDividasVendidas.length }})
                </span>
              </template>
              <DataTable
                class="p-datatable-sm"
                :paginator="true"
                :rows="5"
                :loading="loading"
                :value="compraDividasVendidas"
                dataKey="id"
                :filters.sync="filtros"
                :globalFilterFields="[
                  'rubrica.rubrica',
                  'mesReferencia',
                  'anoReferencia',
                  'numeroContrato',
                  'compraDivida.proposta.statusProposta.nome',
                ]"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} consignações"
                rowGroupMode="subheader"
                groupRowsBy="compraDivida.id"
                sortMode="single"
                sortField="compraDivida.id"
                :sortOrder="1"
                responsiveLayout="scroll">
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <template #empty> Nenhuma consignação encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>

                <Column field="compraDivida.id" header="#"></Column>

                <template #groupheader="{ data }">
                  <td colspan="1" style="width: 15%">
                    <span class="label">Proposta cadastrada em:</span>
                    {{
                      data.compraDivida.proposta.auditMetadata.cadastradoEm
                        | formatarData
                    }}
                  </td>
                  <td colspan="7" style="width: 95%">
                    <Button
                      v-tooltip.left="'Histórico'"
                      icon="pi pi-list"
                      class="mt-2 mr-2 p-button-rounded p-button float-right"
                      @click="exibirHistorico(data.compraDivida.historico)" />
                    <Button
                      v-if="exibirBotaoRecusar(data.compraDivida)"
                      title="Recusar Compra"
                      icon="pi pi-times"
                      class="mt-2 mr-2 p-button-rounded p-button-danger float-right"
                      @click="
                        confirmarRecusarCompraDivida(data.compraDivida)
                      " />
                    <Button
                      v-if="exibirBotaoAprovar(data.compraDivida)"
                      id="aprovar"
                      icon="pi pi-check"
                      class="mt-2 mr-2 p-button-rounded p-button-success float-right"
                      title="Aceitar Compra"
                      @click="confirmarAprovarProposta(data.compraDivida)" />
                  </td>
                </template>

                <template #groupfooter="{ data }">
                  <td colspan="8" style="text-align: right">
                    <span class="label">Total de consignações envolvidas:</span>
                    {{ totalDeConsignacoesEnvolvidas(data.compraDivida.id) }}
                  </td>
                </template>

                <Column field="mesReferencia" header="Mês/Ano">
                  <template #body="{ data }">
                    {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                      data.anoReferencia
                    }}
                  </template>
                </Column>

                <Column :sortable="true" header="Consignatária Compradora">
                  <template #body="{ data }">
                    {{
                      typeof data.compraDivida.proposta.rubrica !== 'undefined'
                        ? data.compraDivida.proposta.rubrica.consignataria
                            .nomeFantasia
                          ? data.compraDivida.proposta.rubrica.consignataria
                              .nomeFantasia
                          : data.compraDivida.proposta.rubrica.consignataria
                              .razaoSocial
                        : ''
                    }}
                  </template>
                </Column>

                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.rubrica
                        : ''
                    }}
                    -
                    {{
                      typeof data.rubrica !== 'undefined'
                        ? data.rubrica.nome
                        : ''
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="numeroContrato"
                  header="Contrato" />
                <Column header="Prazo">
                  <template #body="{ data }">
                    {{
                      data.prazoRestante + data.prazoTotal == 0
                        ? '-'
                        : data.prazoRestante + '/'
                    }}{{ data.prazoTotal == 0 ? '' : data.prazoTotal }}
                  </template>
                </Column>
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column :sortable="true" header="Situação">
                  <template #body="{ data }">
                    <Chip
                      v-if="
                        data.compraDivida.proposta.statusProposta.nome ===
                        'Pendente'
                      "
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="
                        data.compraDivida.proposta.statusProposta.nome ===
                        'Aprovada'
                      "
                      label="Aprovada"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="
                        data.compraDivida.proposta.statusProposta.nome ===
                        'Cancelada'
                      "
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="
                        data.compraDivida.proposta.statusProposta.nome ===
                        'Reprovada'
                      "
                      label="Reprovada"
                      class="mr-2 mb-2 custom-chip suspensa" />
                  </template>
                </Column>
                <Column header="Ações"> </Column>
              </DataTable>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>
                  Proposta de portabilidade ({{
                    compraDividasCompradas.length
                  }})
                </span>
              </template>
              <DataTable
                class="p-datatable-sm"
                :paginator="true"
                :rows="5"
                stripedRows
                :loading="loading2"
                :value="compraDividasCompradas"
                dataKey="id"
                :filters.sync="filtros2"
                :globalFilterFields="[
                  'proposta.rubrica.rubrica',
                  'proposta.mesReferencia',
                  'proposta.anoReferencia',
                  'proposta.numeroContrato',
                  'proposta.statusProposta.nome',
                ]"
                filterDisplay="menu"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="rowsPerPageOptions"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords2} compras de dívidas"
                responsiveLayout="scroll">
                <template #header>
                  <div class="flex flex-column sm:flex-row">
                    <span class="p-input-icon-left mb-2 mr-2">
                      <i class="pi pi-search" />
                      <InputText
                        v-model="filtros2['global'].value"
                        placeholder="Pesquisar"
                        style="width: 100%" />
                    </span>
                    <Button
                      type="button"
                      icon="pi pi-filter-slash"
                      label="Limpar"
                      class="p-button-outlined mb-2"
                      @click="limparFiltro" />
                  </div>
                </template>
                <template #empty> Nenhuma portabilidade encontrada. </template>
                <template #loading> Carregando. Por favor aguarde. </template>
                <Column field="mesReferencia" header="Mês/Ano" class="col-4">
                  <template #body="{ data }">
                    {{ ('0' + data.proposta.mesReferencia).slice(-2) }}/{{
                      data.proposta.anoReferencia
                    }}
                  </template>
                </Column>
                <Column :sortable="true" header="Consignatária Compradora">
                  <template #body="{ data }">
                    {{
                      typeof data.proposta.rubrica
                        ? data.proposta.rubrica.consignataria.razaoSocial
                        : ' - '
                    }}
                  </template>
                </Column>
                <Column :sortable="true" field="rubrica" header="Rubrica">
                  <template #body="{ data }">
                    {{
                      typeof data.proposta
                        ? data.proposta.rubrica.rubrica
                        : ' - '
                    }}
                    -
                    {{
                      typeof data.proposta ? data.proposta.rubrica.nome : ' - '
                    }}
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="proposta.contrato"
                  header="Contrato" />
                <Column field="proposta.prazoTotal" header="Prazo" />
                <Column header="Valor da Parcela">
                  <template #body="{ data }">
                    <div
                      class="flex justify-content-end flex-wrap card-container">
                      <div
                        class="flex align-items-center justify-content-center">
                        {{ data.proposta.valorParcela | formatarValor }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :sortable="true"
                  field="proposta.statusProposta.nome"
                  header="Situação">
                  <template #body="{ data }">
                    <Chip
                      v-if="data.proposta.statusProposta.nome === 'Pendente'"
                      label="Pendente"
                      class="mr-2 mb-2 custom-chip pendente" />
                    <Chip
                      v-if="data.proposta.statusProposta.nome === 'Aprovada'"
                      label="Aprovada"
                      class="mr-2 mb-2 custom-chip ativa" />
                    <Chip
                      v-if="data.proposta.statusProposta.nome === 'Cancelada'"
                      label="Cancelada"
                      class="mr-2 mb-2 custom-chip cancelada" />
                    <Chip
                      v-if="data.proposta.statusProposta.nome === 'Reprovada'"
                      label="Reprovada"
                      class="mr-2 mb-2 custom-chip suspensa" />
                  </template>
                </Column>
                <Column header="Cadastrado Em">
                  <template #body="{ data }">
                    {{
                      data.proposta.auditMetadata.cadastradoEm | formatarData
                    }}
                  </template>
                </Column>
                <Column header="Ações">
                  <template #body="{ data }">
                    <Button
                      v-tooltip.left="'Detalhes'"
                      icon="pi pi-eye"
                      class="mt-2 mr-2 p-button-rounded p-button-success"
                      @click="exibirDetalheProposta(data)" />
                    <Button
                      v-tooltip.left="'Consignações Negociadas'"
                      icon="pi pi-dollar"
                      class="mt-2 mr-2 p-button-rounded p-button-warning"
                      @click="
                        exibirConsignacoesNegociadas(data.consignacoes)
                      " />
                    <Button
                      v-if="exibirBotaoCancelar(data)"
                      v-tooltip.left="'Cancelar Compra'"
                      icon="pi pi-times"
                      class="mt-2 mr-2 p-button-rounded p-button-danger"
                      @click="confirmarCancelarCompra(data)" />
                    <Button
                      v-tooltip.left="'Histórico'"
                      icon="pi pi-list"
                      class="mt-2 mr-2 p-button-rounded p-button"
                      @click="exibirHistoricoProposta(data.proposta.id)" />
                  </template>
                </Column>
              </DataTable>
            </TabPanel>
          </TabView>
        </template>
      </Card>

      <ModalConfirmarOperacao
        :exibir="exibirModalAprovar"
        :metodo="aprovarProposta"
        :msg="'Deseja aceitar a proposta?'"
        @mudarVisibilidade="
          exibirModalAprovar = $event
        "></ModalConfirmarOperacao>

      <ModalConfirmarOperacao
        :exibir="exibirModalCancelarCompra"
        :operacao="'CancelarCompraDivida'"
        :metodo="cancelarCompra"
        :msg="'Deseja cancelar a compra?'"
        @mudarVisibilidade="exibirModalCancelarCompra = $event">
      </ModalConfirmarOperacao>

      <ModalConfirmarOperacao
        :exibir="exibirModalRecusarCompra"
        :operacao="'RecusarCompraDivida'"
        :metodo="recusarCompra"
        :msg="'Deseja recusar a compra?'"
        @mudarVisibilidade="
          exibirModalRecusarCompra = $event
        "></ModalConfirmarOperacao>

      <ConsignacoesNegociadasDialog
        :visible.sync="showConsignacoesNegociadasDialog"
        :consignacoes="consignacoesNegociadas" />
      <Dialog
        :visible.sync="modalHistorico"
        :containerStyle="{ width: '50vw' }">
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="historico"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="rowsPerPageOptions"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} Histórico de Portabilidade"
          responsiveLayout="scroll">
          <template #header>Histórico</template>
          <template #empty> Nenhum histórico encontrado. </template>
          <template #loading> Carregando. Por favor aguarde. </template>
          <Column header="Alterado Em">
            <template #body="{ data }">
              {{ data.alteradoEm | formatarData }}
            </template>
          </Column>
          <Column header="Alterado Por">
            <template #body="{ data }">
              {{ data.nomeAlteradoPor }}
            </template>
          </Column>
          <Column field="descricaoStatus" header="Status" />
        </DataTable>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import CompraDividaService from '@/service/CompraDividaService'
import PropostaService from '@/service/PropostaService'
import CompraDivida from '@/domain/CompraDivida.js'
import ModalConfirmarOperacao from '@/components/shared/modal/modalConfirmarOperacao.vue'
import auth from '@/modules/auth'
import { consignatariaStore } from '@/stores/consignataria'
import ConsignacoesNegociadasDialog from '@/components/shared/modal/consignacoesNegociadasDialog.vue'

export default {
  components: {
    ModalConfirmarOperacao,
    ConsignacoesNegociadasDialog,
  },

  setup() {
    const storeConsignataria = consignatariaStore()
    return { storeConsignataria }
  },

  data() {
    return {
      filtros: {},
      filtros2: {},
      loading: false,
      loading2: false,
      totalRecords: 0,
      totalRecords2: 0,
      compraDividasVendidas: [],
      compraDividasCompradas: [],
      compraDivida: new CompraDivida(),
      idMatricula: this.$route.params.id,
      exibirModalAprovar: false,
      auth,
      showConsignacoesNegociadasDialog: false,
      consignacoesNegociadas: [],
      exibirModalCancelarCompra: false,
      exibirModalRecusarCompra: false,
      STATUS_PENDENTE: 'Pendente',
      modalHistorico: false,
      historico: [],
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.service = new CompraDividaService(this.$http)
    this.serviceProposta = new PropostaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarComprasDividasVendidas()
    this.carregarComprasDividasCompradas()
  },

  methods: {
    limparFiltro() {
      this.initFiltros()
    },

    exibirHistorico(historico) {
      this.historico = historico
      this.modalHistorico = true
    },
    exibirHistoricoProposta(propostaId) {
      this.service.findByPropostaConsignacaoId(propostaId).then((response) => {
        this.historico = response.historico
        this.modalHistorico = true
      })
    },
    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        'compraDivida.proposta.statusProposta.nome': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
      this.filtros2 = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
        'proposta.statusProposta.nome': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    carregarComprasDividasVendidas() {
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )

      this.service
        .getListaCompraDividasVendidasPorMatriculaEConsignatariaSelecionada(
          this.idMatricula,
          consignataria.id,
        )
        .then(
          (res) => {
            this.compraDividasVendidas = res
          },
          (err) => {
            this.exibeToast(err.response.data.message)
          },
        )
    },

    carregarComprasDividasCompradas() {
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )

      this.service
        .getListaCompraDividasCompradasPorMatriculaEConsignatariaSelecionada(
          this.idMatricula,
          consignataria.id,
        )
        .then(
          (res) => {
            this.compraDividasCompradas = res
          },
          (err) => {
            this.exibeToast(err.response.data.message)
          },
        )
    },

    exibirDetalheProposta(data) {
      this.$router.push({
        name: 'compra-divida-detalhe',
        params: { idcompradivida: data.id },
      })
    },

    exibirConsignacoesNegociadas(consignacoes) {
      this.consignacoesNegociadas = consignacoes
      this.showConsignacoesNegociadasDialog = true
    },

    exibirBotaoAprovar(compraDivida) {
      if (
        typeof compraDivida.proposta != 'undefined' &&
        typeof compraDivida.proposta.statusProposta != 'undefined' &&
        (auth.hasRoleConsignatariaAdmin() ||
          auth.hasRoleConfirmarCompraDivida())
      ) {
        return compraDivida.proposta.statusProposta.nome == this.STATUS_PENDENTE
          ? true
          : false
      } else {
        return false
      }
    },

    exibirBotaoRecusar(compraDivida) {
      if (
        typeof compraDivida.proposta != 'undefined' &&
        typeof compraDivida.proposta.statusProposta != 'undefined' &&
        (auth.hasRoleConsignatariaAdmin() ||
          auth.hasRoleCancelarSolicitacaoCompraDivida())
      ) {
        return compraDivida.proposta.statusProposta.nome == this.STATUS_PENDENTE
          ? true
          : false
      } else {
        return false
      }
    },

    exibirBotaoCancelar(data) {
      if (
        typeof data.proposta != 'undefined' &&
        typeof data.proposta.statusProposta != 'undefined' &&
        (auth.hasRoleConsignatariaAdmin() ||
          auth.hasRoleCancelarSolicitacaoCompraDivida())
      ) {
        return data.proposta.statusProposta.nome == this.STATUS_PENDENTE
          ? true
          : false
      } else {
        return false
      }
    },

    confirmarAprovarProposta(data) {
      this.compraDivida = data
      this.exibirModalAprovar = true
    },

    aprovarProposta() {
      this.serviceProposta.aprovarProposta(this.compraDivida.proposta).then(
        () => {
          this.exibeToast('success', 'Aprovado com sucesso')
          this.limpar()
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    confirmarRecusarCompraDivida(data) {
      this.compraDivida = data
      this.exibirModalRecusarCompra = true
    },

    recusarCompra(observacao) {
      this.compraDivida.proposta.obs = observacao
      this.serviceProposta
        .reprovarProposta(this.compraDivida.proposta)
        .then(() => {
          this.exibeToast('success', 'Recusado com sucesso')
          this.limpar()
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
    },

    confirmarCancelarCompra(data) {
      this.compraDivida = data
      this.exibirModalCancelarCompra = true
    },

    cancelarCompra(observacao) {
      this.compraDivida.proposta.obs = observacao
      this.serviceProposta
        .cancelar(this.compraDivida.proposta)
        .then(() => {
          this.exibeToast('success', 'Cancelado com sucesso')
          this.limpar()
        })
        .catch((err) => {
          this.exibeToast('error', err.response.data.message)
        })
    },

    totalDeConsignacoesEnvolvidas(idCompraDivida) {
      let total = 0

      if (this.compraDividasVendidas) {
        for (let consignacaoVendida of this.compraDividasVendidas) {
          if (consignacaoVendida.compraDivida.id === idCompraDivida) {
            total++
          }
        }
      }

      return total
    },

    exibeToast(severity, msg) {
      this.$toast.add({
        severity: severity,
        summary: msg,
        life: 10000,
      })
    },

    limpar() {
      this.initFiltros()
      this.compraDivida = null
      this.carregarComprasDividasVendidas()
      this.carregarComprasDividasCompradas()
    },
  },
}
</script>

<style lang="scss" scoped>
.pendente {
  color: #1f2d3d;
  background-color: #ffc107;
  font-weight: bold;
}
.ativa {
  color: #fff;
  background-color: #007bff;
  font-weight: bold;
}
.cancelada {
  color: #fff;
  background-color: #dc3545;
  font-weight: bold;
}
.suspensa {
  color: #1f2d3d;
  background-color: #c0c0c0;
  font-weight: bold;
}
.quitada {
  color: #fff;
  background-color: #28a745;
  font-weight: bold;
}

::v-deep .p-datatable-tbody .p-rowgroup-header {
  background-color: #f2f2f2;
}

.label {
  font-weight: bold;
}
</style>
